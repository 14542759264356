var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.value,
      options: _vm.options,
      loading: _vm.loading,
      disabled: _vm.disabled,
      "filter-option": false,
      "dropdown-match-select-width": false,
      "label-in-value": _vm.labelInValue,
      placeholder: _vm.$t("common.select-text", {
        text: _vm.$t("lbl_customer")
      }),
      "allow-clear": _vm.allowClear,
      "show-search": ""
    },
    on: {
      search: _vm.onSearch,
      change: _vm.onChange,
      scrollEnd: _vm.onScrollEnd
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function(ref) {
          var option = ref.option
          return [_vm._v(" " + _vm._s(option.label) + " ")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }