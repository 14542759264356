




















import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks/contact";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_PAGE, ONE } from "@/models/constant/global.constant";
import { LabelInValue } from "@/types";
import { ListContactDataDto } from "@interface/contact-data";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectCustomer extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  allowClear!: boolean;

  options: Option<ListContactDataDto>[] = [];

  loading = false;

  pagination = {
    page: DEFAULT_PAGE,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(this.buildParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findCustomers, toOptionsCustomer } = useContactData();
    this.loading = true;
    findCustomers(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptionsCustomer(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: string | LabelInValue): void {
    if (!e) {
      this.onSearch();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useContactData();
    this.resetState();
    this.pagination.search = filterBy({
      firstName: val,
      lastName: val,
    });

    this.fetchOptions(this.buildParams());
  }

  findOption(
    value?: string | LabelInValue
  ): Option<ListContactDataDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;
    params.page = page - ONE;
    params.search = search;
    return params;
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  resetState(): void {
    this.pagination.page = ONE;
    this.options = [];
    this.allLoaded = false;
  }
}
